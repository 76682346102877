<template>
  <resize-box
    ref="resizeBox"
    class="videoBox"
    @resize="resizeHander"
    :resize-el="''"
    @done="resizeHander($event, 'done')"
  >
    <form onsubmit="return false;">
      <div :id="idEl"></div>
    </form>
  </resize-box>
</template>

<script>
import $ from "jquery";
import "mui-player/dist/mui-player.min.css";
import MuiPlayer from "mui-player";
import MuiPlayerDesktopPlugin from "mui-player-desktop-plugin";
import customSetting from "./customSetting.js";
import ResizeBox from "@/components/com/el/resizeBox";

export default {
  components: { ResizeBox },
  props: {
    reloadUrl: {
      default: () => "",
    },
    course: {
      default: () => "",
    },
    autoplay: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    customSetting: {
      type: [Boolean, Array],
      default: () => {
        return true;
      },
    },
  },
  data() {
    return {
      player: "",
      src: "",
      idEl: "videoBox" + new Date().getTime(),
      duration: 0,
      currentTime: 0,
      boxStyle: {
        width: 0,
        height: 0,
        videoWidth: 0,
        videoHeight: 0,
        videoRoate: null,
      },
      timer: null,
    };
  },
  watch: {
    reloadUrl(v) {
      if (this.boxStyle.width > 0 && this.boxStyle.height > 0) {
        this.start(v);
      }
    },
    boxStyle: {
      deep: true,
      immediate: true,
      handler(v, o) {
        v = { ...(v || {}) };
        o = { ...(o || {}) };
        if (v.videoWidth > 0) {
          this.$nextTick(() => {
            this.resizeHander({ ...v });
          });
        }
      },
    },
  },
  updated() {
    if (this.player) {
      if (this.autoplay && this.player.paused) {
        this.play();
      }
      if (!this.autoplay && !this.player.paused) {
        this.pause();
      }
    }
  },
  methods: {
    resizeHander({ width, height }, emit) {
      this.boxStyle.width = width;
      this.boxStyle.height = height;
      this.$emit("resize", { width, height });
      if (emit) {
        this.start(this.reloadUrl);
      } else {
        var Mplayer = $("#" + this.idEl);
        Mplayer.css("width", this.boxStyle.width + "px");
        Mplayer.css("height", this.boxStyle.height + "px");
        if (this.boxStyle.videoRoate !== null) {
          this.getVideo().width =
            this.boxStyle.width * this.boxStyle.videoRoate;
          this.getVideo().height =
            this.boxStyle.height * this.boxStyle.videoRoate;
        }
      }
    },
    start(src) {
      if (
        src &&
        src !== this.src &&
        (this.$refs.resizeBox || {}).loading === false
      ) {
        if (!this.player) {
          var plugins = [];
          if (this.customSetting !== false) {
            plugins.push(
              new MuiPlayerDesktopPlugin({
                customSetting:
                  this.customSetting === true
                    ? customSetting
                    : this.customSetting,
              })
            );
          }
          //初始化视频
          this.player = new MuiPlayer({
            container: "#" + this.idEl,
            pageHead: false,
            showMiniProgress: false,
            src: src,
            plugins: plugins,
          });
        } else {
          this.player.reloadUrl(src);
        }
        this.duration = 0;
        this.currentTime = 0;
        this.listener();
        if (this.autoplay) {
          setTimeout(() => {
            this.play();
          }, 150);
        }
      }
    },
    //暂停视频
    pause: function () {
      try {
        if (!this.getVideo().paused) {
          this.$nextTick(() => {
            this.getVideo().pause();
          });
        }
      } catch (e) {}
    },
    //播放视频
    play: function () {
      try {
        if (this.getVideo().paused) {
          this.$nextTick(() => {
            this.getVideo().play();
          });
        }
      } catch (e) {
        // console.log("play error:" , e);
      }
    },
    getVideo() {
      return this.player ? this.player.video() : undefined;
    },
    listener() {
      let video = this.getVideo();
      if (video) {
        var Mplayer = $("#" + this.idEl);
        Mplayer.css("width", this.boxStyle.width + "px");
        Mplayer.css("height", this.boxStyle.height + "px");
        //视频查找。当浏览器开始寻找指定的音频/视频时触发，也就是当加载过程开始时
        video.addEventListener("loadstart", (e) => {
          var videoWidth = $(video).width(),
            videoHeight = $(video).height(),
            roate = Math.max(
              videoWidth / this.boxStyle.width,
              videoHeight / this.boxStyle.height
            );
          this.boxStyle.videoWidth = videoWidth;
          this.boxStyle.videoHeight = videoHeight;
          this.boxStyle.videoRoate = roate;
        });
        //时长变化。当指定的音频/视频的时长数据发生变化时触发
        video.addEventListener("durationchange", (e) => {
          this.$emit("videoTime", video.duration);
        });
        //元数据加载。当指定的音频/视频的元数据已加载时触发
        video.addEventListener("loadedmetadata", (e) => {});
        //视频下载监听。
        video.addEventListener("loadeddata", (e) => {});
        //浏览器下载监听。
        video.addEventListener("progress", (e) => {});
        //查找开始。当用户开始移动/跳跃到音频/视频中新的位置时触发
        video.addEventListener("seeking", (e) => {});
        //查找结束。当用户已经移动/跳跃到视频中新的位置时触发
        video.addEventListener("seeked", (e) => {});
        //视频加载等待。当视频由于需要缓冲下一帧而停止，等待时触发
        video.addEventListener("waiting", (e) => {});
        //当视频在已因缓冲而暂停或停止后已就绪时触发
        video.addEventListener("playing", (e) => {});
        //播放错误
        video.addEventListener("error", (e) => {});
        //当音量更改时
        video.addEventListener("volumechange", (e) => {});
        //当浏览器尝试获取媒体数据，但数据不可用时
        video.addEventListener("stalled", (e) => {});
        //当视频的播放速度已更改时
        video.addEventListener("ratechange", (e) => {});
        //可播放监听
        video.addEventListener("canplay", (e) => {});
        //可流畅播放
        video.addEventListener("canplaythrough", (e) => {});
        //video
        video.addEventListener("pause", (e) => {
          clearInterval(this.timer)
          this.$emit("timeupdate", {
                duration: this.player.video().duration,
                currentTime: this.player.video().currentTime,
              });
        });
        // 监听开始播放事件
        video.addEventListener(
          "play",
          (e) => {
            this.$emit("play", e);
            this.timer = setInterval(() => {
              this.$emit("timeupdate", {
                duration: this.player.video().duration,
                currentTime: this.player.video().currentTime,
              });
            }, 5000);
          },
          false
        );

        // 监听播放进度更新事件
        // video.addEventListener(
        //   "timeupdate",
        //   (e) => {
        //     this.duration = video.duration;
        //     this.currentTime = video.currentTime;
        //     console.log(this.player.video().currentTime, "currentTime");
        //     // this.$emit("timeupdate", {duration :video.duration ,currentTime : video.currentTime});
        //   },
        //   false
        // );
        // 监听播放结束事件
        video.addEventListener(
          "ended",
          (e) => {
            this.$emit("ended", e);
            
            clearInterval(this.timer)
            this.$emit("timeupdate", {
                duration: this.player.video().duration,
                currentTime: this.player.video().currentTime,
              });
          },
          false
        );
      }
    },
  },

  beforeDestroy() {
    clearInterval(this.timer)

    try {
      this.player && this.player.destory();
    } catch (e) {
      // console.log(e);
    }
  },
};
</script>

<style lang="less" scoped>
::v-deep.videoBox {
  width: 100%;
  height: 100%;
  max-width: 100vw;
  position: relative;
  .m-player {
    position: absolute;
    top: 0;
    // bottom:0;
    right: 0;
    // left:0;
    max-width: 100%;
    background-color: transparent;
    .player-wrapper .video-wrapper video {
      height: 100%;
      width: 100%;
      max-height: 100%;
      max-width: 100%;
    }
  }
}
</style>
